export default {
    "general": {
        "ISTOBAL"                     : "ISTOBAL"
    },
    "menu": {
        "Live"                        : "Live",
        "Media"                       : "Media",
        "VRExperience"                : "AR Experience",
        "Contact"                     : "Contact",
    },
    "countdown": {
        "title"                       : "Private event",
        "description"                 : "Request access to your commercial.",
    },
    "pre": {
        "title"                       : "You are one step away from feeling something unique",
        "description"                 : "Welcome to the ISTOBAL virtual event to present our new rollover. What if we could connect technology and emotion? Find out on November 8th at 11:00 (UTC+1) in our live broadcast.",
        "backgroundAlt"               : "Technology and emotion",
        "teaserTitle"                 : "Technology and emotion",
        "vimeoTeaserURL"              : "https://player.vimeo.com/video/637802566?h=0b4194e800",
    },
    "durante": {
        "title"                       : "Welcome to the ISTOBAL virtual event to present our new rollover",
        "description"                 : "What if we could connect technology and emotion?",
        "live"                        : "Live",
        "vimeoVideoURL"               : "https://vimeo.com/event/1415656/embed/108317c867",
        "vimeoChatURL"                : "https://vimeo.com/event/1184834/chat/",
    },
    "post": {
        "title"                       : "Did you miss the virtual event to present our brand new ISTOBAL M'WASH4 rollover?",
        "description"                 : "Don't worry, you can watch it here .What if we could connect technology and emotion?",
        "vimeoVideoURL"               : "https://player.vimeo.com/video/643475333?h=4a3aa290bd",
        "vimeoYoutubeURL"             : "https://www.youtube.com/embed/zLfXY_RXvxk?rel=0",
        "surveyTitle"                 : "What do you think about our brand new M'WASH4?",
        "surveyDescription"           : "We want to know your feedback",
        "surveyButtonText"            : "Access the survey",
        "surveyURL"                   : "https://oufv23ymd2d.typeform.com/to/bXGaZm0z?utm_source=typeform&utm_medium=website&utm_campaign=mwash4&utm_content=give-us-feedback",
    },
    "media": {
        "title"                       : "Media",
        "vimeoVideoSpotURL"           : "https://player.vimeo.com/video/637373830?h=5f38fdbb51",
        "vimeoVideoTechnicalURL"      : "https://player.vimeo.com/video/639874339?h=08823c1ae1",
        "downloadPDF"                 : "Download PDF",
        "catalogPDFName"              : "M'WASH4 catalog",
        "catalogPDFAlt"               : "New rollover M'WASH4",
        "catalogPDFURL"               : "/pdf/Catalog_MWASH4.pdf",
        "pressReleasePDFName"         : "Press Release",
        "pressReleasePDFAlt"          : "M'WASH4 press release",
        "pressReleasePDFURL"          : "/pdf/Press-Release-ISTOBAL-MWASH4.pdf",
        "viewGallery"                 : "View gallery",
    },
    "ar": {
        "title"                       : "AR Experience",
        "description"                 : "Discover the new ISTOBAL M'WASH4 rollover through the most exciting augmented reality experience."
    },
    "contact": {
        "title"                       : "Contact",
        "preDescription"              : "Do you want to know more? Contact our sales team to find out more details about our brand new rollover.",
        "description"                 : "Do you want to know more? Contact our sales team to find out more details about our new ISTOBAL M'WASH4 rollover.",
        "iframeURL"                   : "/form/contact-en.html",
        "iframeURLThanks"             : "/form/thankyou.html",
    },
    "schedule": {
        "Menu"                        : "Schedule",
        "Title"                       : "Schedule",
    },
    "countDownLabels": {
        "days"                        : "days",
        "hours"                       : "hours",
        "minutes"                     : "minutes",
        "seconds"                     : "seconds"
    }
}