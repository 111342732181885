import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'



Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    redirect: '/'+navigator.language.split('-')[0]
  },
  {
    path: '/:locale',
    name: 'Home',
    component: App,
    props: true,

  },

]

const router = new VueRouter({
  mode:'history',
  hash: false,
  base: process.env.BASE_URL,
  routes
})

export default router
