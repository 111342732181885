import Vue from 'vue'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import VideoEmbed from "v-video-embed";
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import {i18n} from './i18n'

import router from './router'

import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
const routerGTM = new VueRouter();

Vue.use(VueGtm, {
  id: "GTM-5KXGBSV", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: routerGTM, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});
Vue.use(VueAxios, axios)

Vue.use(VideoEmbed)
Vue.config.productionTip = false
Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_SERVER
Vue.axios.defaults.headers = {
  "Content-Type": 'application/json'
}

import Buefy from 'buefy'
import './assets/scss/app.scss'
Vue.use(Buefy);



new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')



