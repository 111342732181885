import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import es from '@/i18n/es.ts';
import en from '@/i18n/en.ts';


const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: {
        en, es
    }
})

export {i18n};
