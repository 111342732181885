<template>
  <div id="app">
    <b-navbar>
        <template #brand>
            <b-navbar-item>
                <img
                    src="./assets/img/istobal.png"
                    :alt="$i18n.t('general.ISTOBAL')"
                >
            </b-navbar-item>
        </template>
        <template #end v-if="message=='Ok'">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <a class="button is-ghost has-text-weight-semibold" href="#live">
                        {{ $i18n.t('menu.Live') }}
                    </a>
                    <a v-if="0==1" class="button is-ghost has-text-weight-semibold" href="#agenda">
                        {{ $i18n.t('menu.Agenda') }}
                    </a>
                    <a class="button is-ghost has-text-weight-semibold" href="#media" v-if="estado!='pre'">
                        {{ $i18n.t('menu.Media') }}
                    </a>
                    <a class="button is-ghost has-text-weight-semibold" href="#vrexperience" v-if="estado!='pre'">
                        {{ $i18n.t('menu.VRExperience') }}
                    </a>
                    <a class="button is-rojo has-text-weight-semibold" href="#contact">
                        {{ $i18n.t('menu.Contact') }}
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
    <section class="section countdown" v-if="message!='Ok'">
      <div class="container">
        <div class="columns">
          <div class="column is-full-tablet titulo">
            <h1 class="has-text-weight-bold is-size-3 has-text-white">{{ $i18n.t('countdown.title') }}</h1>
            <h2 class="has-text-white">{{ $i18n.t('countdown.description') }}</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="live" class="section countdown" v-if="message=='Ok' && estado=='pre'">
      <div class="container">
        <div class="columns">
          <div class="column is-full-tablet titulo">
            <h1 class="has-text-weight-bold is-size-3 has-text-white">{{ $i18n.t('pre.title') }}</h1>
            <h2 class="has-text-white">{{ $i18n.t('pre.description') }}</h2>
          </div>
        </div>
        <div class="columns">
          <div class="column py-6">
            <flip-countdown deadline="2021-11-08 11:00" :labels="deadlinelabels"></flip-countdown>
          </div>
        </div>
        <div class="columns">
          <div class="column is-full pt-4 pb-6">
            <div class="live video-container">
              <iframe :src="$i18n.t('pre.vimeoTeaserURL')" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen :title="$i18n.t('pre.teaserTitle')"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="live" class="section video" v-if="message=='Ok' && estado!='pre'">
      <div class="container">
        <div class="columns">
          <div class="column is-2-tablet has-text-right-tablet now" v-if="estado=='durante'">
            <a class="button mt-1">
              <img svg-inline src="./assets/img/ico-live.svg" alt="live" />
              <span class="has-text-rojo is-uppercase ml-2 has-text-weight-bold">{{ $i18n.t('durante.live') }}</span>
            </a>
          </div>
          <div class="column is-10-tablet titulo" v-if="estado=='durante'">
            <h1 class="has-text-weight-bold is-size-3 has-text-white">{{ $i18n.t('durante.title') }}</h1>
            <h2 class="has-text-white">{{ $i18n.t('durante.description') }}</h2>
          </div>
          <div class="column is-12-tablet titulo" v-if="estado=='post'">
            <h1 class="has-text-weight-bold is-size-3 has-text-white">{{ $i18n.t('post.title') }}</h1>
            <h2 class="has-text-white">{{ $i18n.t('post.description') }}</h2>
          </div>
        </div>
        <div class="columns">
          <div class="column is-full" v-if="estado=='durante'">
            <div class="live video-container">
              <iframe :src="$i18n.t('durante.vimeoVideoURL')" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
            </div>
          </div>
          <div class="column is-8 pt-0" v-if="estado=='post'">
            <div class="live video-container">
              <iframe :src="$i18n.t('post.vimeoVideoURL')" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="column is-4 has-text-white" v-if="estado=='durante' && 0==1">
            <iframe v-if="0==1" :src="$i18n.t('durante.vimeoChatURL')" width="100%" height="100%" frameborder="0"></iframe>
          </div>
          <div class="column is-4 has-text-white" v-if="estado=='post'">
            <div class="banner-encuesta" @click="goURL('surveyURL','post')">
              <div class="fondo">
                <h3 class="is-size-4 has-text-weight-semibold has-text-centered">{{ $i18n.t('post.surveyTitle') }}</h3>
                <p class="has-text-centered is-size-5">{{ $i18n.t('post.surveyDescription') }}</p>
                <a class="button is-fullwidth is-rojo has-text-weight-semibold is-size-5" :href="$i18n.t('post.surveyURL')" target="_blank">
                    {{ $i18n.t('post.surveyButtonText') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="agenda" class="section agenda has-text-white" v-if="message=='Ok' && 0==1">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-four-fifths-tablet is-three-fifths-desktop">
            <div class="columns cabecera is-vcentered px-4 py-4 is-mobile is-multiline">
              <div class="column is-full-mobile is-half-tablet">
                <h3 class="is-size-4 has-text-weight-semibold">{{ $i18n.t('schedule.Title') }}</h3>
                <p class="fecha has-background-black-ter mt-1 px-3 py-1">Martes, <strong class="has-text-white">20 de abril</strong> de 2021</p>
              </div>
              <div class="column is-full-mobile is-half-tablet has-text-left has-text-right-tablet">
                <b-button type="is-rojo" class="has-text-white">
                  <span class="has-text-weight-semibold is-size-4">08</span><br />
                  <span class="has-text-weight-light">MAR</span>
                </b-button>
              </div>
            </div>
            <div class="columns evento is-vcentered">
              <div class="column hora is-3 has-text-left-mobile has-text-centered-tablet">
                <p class="has-text-white">14:30 - 14:40</p>
              </div>
              <div class="column detalle is-9">
                <h4 class="has-text-weight-semibold">Welcome</h4>
                <p>Yolanda Tomás</p>
              </div>
            </div>
            <div class="columns evento is-vcentered">
              <div class="column hora is-3 has-text-left-mobile has-text-centered-tablet">
                <p class="has-text-white">14:30 - 14:40</p>
              </div>
              <div class="column detalle is-9">
                <h4 class="has-text-weight-semibold">Welcome</h4>
                <p>Yolanda Tomás</p>
              </div>
            </div>
            <div class="columns evento now is-vcentered">
              <div class="column hora is-3 has-text-left-mobile has-text-centered-tablet">
                <p class="has-text-white">
                  14:30 - 14:40
                  <a class="button mt-1 ml-3 is-size-7 is-hidden-tablet" v-if="estado=='durante'">
                    <img svg-inline src="./assets/img/ico-live.svg" alt="live" />
                    <span class="has-text-rojo is-uppercase ml-2 has-text-weight-bold">En directo</span>
                  </a>
                </p>
              </div>
              <div class="column detalle is-6">
                <h4 class="has-text-weight-semibold">Welcome</h4>
                <p>Yolanda Tomás</p>
              </div>
              <div class="column hora is-3 has-text-left-mobile has-text-centered-tablet is-hidden-mobile">
                <a class="button mt-1" v-if="estado=='durante'">
                  <img svg-inline src="./assets/img/ico-live.svg" alt="live" />
                  <span class="has-text-rojo is-uppercase ml-2 has-text-weight-bold">En directo</span>
                </a>
              </div>
            </div>
            <div class="columns evento is-vcentered">
              <div class="column hora has-text-left-mobile has-text-centered-tablet">
                <p class="has-text-white">14:30 - 14:40</p>
              </div>
              <div class="column detalle is-9">
                <h4 class="has-text-weight-semibold">Welcome</h4>
                <p>Yolanda Tomás</p>
              </div>
            </div>
            <div class="columns evento is-vcentered">
              <div class="column hora is-3 has-text-left-mobile has-text-centered-tablet">
                <p class="has-text-white">14:30 - 14:40</p>
              </div>
              <div class="column detalle is-9">
                <h4 class="has-text-weight-semibold">Welcome</h4>
                <p>Yolanda Tomás</p>
              </div>
            </div>
            <div class="columns evento is-vcentered">
              <div class="column hora is-3 has-text-left-mobile has-text-centered-tablet">
                <p class="has-text-white">14:30 - 14:40</p>
              </div>
              <div class="column detalle is-9">
                <h4 class="has-text-weight-semibold">Welcome</h4>
                <p>Yolanda Tomás</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section separador has-text-white" v-if="message=='Ok' && estado!='pre'">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-full">
          </div>
        </div>
      </div>
    </section>
    <section id="media" class="section downloads has-text-white" v-if="message=='Ok' && estado!='pre'">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-four-fifths-tablet is-three-fifths-desktop">
            <div class="columns is-multiline is-mobile">
              <div class="column is-full">
                <h3 class="is-size-4 has-text-weight-semibold">{{ $i18n.t('media.title') }}</h3>
              </div>
            </div>
            <div class="columns is-multiline is-mobile">
              <div class="column is-full-mobile is-half-tablet is-clickable" @click="goURL('catalogPDFURL')">
                <div class="documento">
                  <span class="">
                    <img
                          src="./assets/img/ico-pdf.png"
                          :alt="$i18n.t('media.catalogPDFAlt')"
                      >
                  </span>
                  <span class="">
                    <h5 class="is-size-5 mt-1">{{ $i18n.t('media.catalogPDFName') }}</h5>
                    <a :href="$i18n.t('media.catalogPDFURL')" target="_blank" class="has-text-white has-text-weight-semibold">{{ $i18n.t('media.downloadPDF') }}</a>
                  </span>
                </div>
              </div>
              <div class="column is-full-mobile is-half-tablet is-clickable" @click="goURL('pressReleasePDFURL')">
                <div class="documento block">
                  <span class="">
                    <img
                          src="./assets/img/ico-pdf.png"
                          :alt="$i18n.t('media.pressReleasePDFAlt')"
                      >
                  </span>
                  <span class="">
                    <h5 class="is-size-5 mt-1">{{ $i18n.t('media.pressReleasePDFName') }}</h5>
                    <a :href="$i18n.t('media.pressReleasePDFURL')" target="_blank" class="has-text-white has-text-weight-semibold">{{ $i18n.t('media.downloadPDF') }}</a>
                  </span>
                </div>
              </div>
              <div class="column is-full px-0">
                <div class="video-container">
                  <iframe :src="$i18n.t('media.vimeoVideoSpotURL')" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="ISTOBAL_esens_2021_ING.mp4"></iframe>
                </div>
              </div>
              <div class="column is-full px-0">
                <div class="video-container">
                  <iframe  :src="$i18n.t('media.vimeoVideoTechnicalURL')" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="ISTOBAL_esens_2021_ING.mp4"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section gallery mobile is-hidden-tablet" v-if="message=='Ok' && estado!='pre'">
      <div class="container">
        <div class="columns is-mobile is-multiline">
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-01.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-02.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-03.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-04.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-full">
            <a class="button is-rojo is-fullwidth has-text-weight-semibold" @click="switchGallery(true)">
                <span><img src="./assets/img/ico-gallery.png" alt="Photos" /></span><br />
                {{ $i18n.t('media.viewGallery') }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="vrexperience" class="section gallery tablet px-2 is-hidden-mobile" v-if="message=='Ok' && estado!='pre'">
      <div class="container px-0">
        <div class="columns is-variable is-1 px-0">
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-01.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-02.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-hidden-mobile is-one-fifth-tablet">
            <a class="button is-rojo is-fullwidth has-text-weight-semibold" @click="switchGallery(true)">
                <span><img src="./assets/img/ico-gallery.png" alt="Photos" /></span><br />
                {{ $i18n.t('media.viewGallery') }}
            </a>
          </div>
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-03.jpg"
                  alt="Photo"
              >
            </a>
          </div>
          <div class="column is-half-mobile is-one-fifth-tablet">
            <a @click="switchGallery(true)">
              <img
                  src="./assets/img/gallery-mwash4-04.jpg"
                  alt="Photo"
              >
            </a>
          </div>
        </div>
      </div>
    </section>
    <div :class="gallery ? '' : 'is-hidden'" v-if="message=='Ok' && estado!='pre'">
      <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="gallery" @click="switchGallery(true)">
          <b-carousel-item v-for="(i, idx) in galleryImages" :key="idx">
              <a class="image ">
                  <img :src="i.src">
              </a>
          </b-carousel-item>
          <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>
          <template #indicators="props">
              <figure class="al image" :draggable="false">
                  <img :draggable="false" :src="galleryImages[props.i].src">
              </figure>
          </template>
      </b-carousel>
    </div>
    <section class="section vrexperience mobile has-text-white is-hidden-tablet" v-if="message=='Ok' && estado!='pre'">
      <div class="container">
        <div class="columns is-mobile is-multiline">
          <div class="column is-full px-5">
            <h3 class="is-size-4 has-text-centered has-text-weight-semibold">{{ $i18n.t('ar.title') }}</h3>
            <p class="has-text-centered">{{ $i18n.t('ar.description') }}</p>
          </div>
          <div class="column is-full has-text-centered qr">
            <a href="https://www.deusens.com/istobal/" target="_blank" rel="noopener noreferrer"><img src="./assets/img/QR-Code.png" alt="QR code" width="354" height="347" /></a>
          </div>
          <div class="column is-full fondo">
            <img src="./assets/img/vr-experience-example-mobile.jpg" :alt="$i18n.t('ar.title')" width="752" height="761" />
          </div>
        </div>
      </div>
    </section>
    <section class="section vrexperience tablet has-text-white is-hidden-mobile pl-0" v-if="message=='Ok' && estado!='pre'">
      <div class="container is-fluid pl-0">
        <div class="columns is-vcentered">
          <div class="column is-5 mx-0 pl-0">
            <img src="./assets/img/vr-experience-example.jpg" :alt="$i18n.t('ar.title')" width="752" height="761" />
          </div>
          <div class="column is-2 has-text-centered qr">
            <a href="https://ar.istobal.com" target="_blank"><img src="./assets/img/QR-Code.png" alt="QR code" width="354" height="347" /></a>
          </div>
          <div class="column texto is-5 px-5">
            <h3 class="is-size-4 has-text-weight-semibold">{{ $i18n.t('ar.title') }}</h3>
            <p class="">{{ $i18n.t('ar.description') }}</p>
          </div>
        </div>
      </div>
    </section>
    <section id="contact" class="section asesorarte" v-if="message=='Ok'">
      <div class="container">
        <div class="columns">
          <div class="column has-text-left">
            <h3 class="is-size-3 mb-4 has-text-weight-semibold has-text-black">{{ $i18n.t('contact.title') }}</h3>
            <p v-if="message=='Ok' && estado=='pre'">{{ $i18n.t('contact.preDescription') }}</p>
            <p v-if="message=='Ok' && estado!='pre'">{{ $i18n.t('contact.description') }}</p>
          </div>
          <div class="column" style="min-height: 555px;">
            <div class="iframe">
              <iframe :src="$i18n.t('contact.iframeURL')" allowtransparency="true" width="100%" height="555px" type="text/html" frameborder="0" style="border:0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="container is-max-desktop">
        <div class="columns">
          <div class="column is-half logo has-text-centered-mobile">
            <figure class="image">
              <img src="./assets/img/istobalx2.png" width="512" height="112" alt="ISTOBAL. Vehicle Wash & Care">
            </figure>
          </div>
          <div class="column is-half">
            <div class="columns is-mobile social">
              <div class="column is-hidden-mobile is-2-desktop"></div>
              <div class="column is-one-quarter-mobile is-2-desktop is-offset-2-desktop">
                <a href="https://www.youtube.com/user/ISTOBALTV/" target="_blank" rel="noopener noreferrer">
                  <figure class="image">
                    <img src="./assets/img/ico-youtube.png" width="71" height="68" alt="Youtube" />
                  </figure>
                </a>
              </div>
              <div class="column is-one-quarter-mobile is-2-desktop">
                <a href="https://www.linkedin.com/company/istobal-s-a-?trk=biz-companies-cym" target="_blank" rel="noopener noreferrer">
                <figure class="image">
                  <img src="./assets/img/ico-linkedin.png" width="65" height="68" alt="LindedIn" />
                </figure>
              </a>
              </div>
              <div class="column is-one-quarter-mobile is-2-desktop">
                <a href="https://twitter.com/istobal_corp" target="_blank" rel="noopener noreferrer">
                  <figure class="image">
                    <img src="./assets/img/ico-twitter.png" width="78" height="68" alt="Twitter" />
                  </figure>
                </a>
              </div>
              <div class="column is-one-quarter-mobile is-2-desktop has-text-right">
                <a href="https://www.facebook.com/istobal.corp" target="_blank" rel="noopener noreferrer">
                  <figure class="image">
                    <img src="./assets/img/ico-facebook.png" width="36" height="68" alt="Facebook" />
                  </figure>
                </a>
              </div>
              <div class="column is-hidden-mobile is-1-desktop"></div>
            </div>
          </div>
        </div>
        <div class="columns legal">
          <div class="column is-half">
            <p class="has-text-centered-mobile"><a href="https://istobal.com" target="_blank" rel="noopener noreferrer">www.istobal.com</a> | &copy; ISTOBAL . All rights reserved</p>
          </div>
          <div class="column is-half">
            <div class="columns is-mobile">
              <div class="column is-hidden-mobile is-4-desktop"></div>
              <div class="column is-narrow-desktop has-text-centered-tablet">
                <a href="https://int.istobal.com/inter/condiciones-generales/" target="_blank" rel="noopener noreferrer">General Terms</a>
              </div>
              <div class="column is-narrow-desktop has-text-centered-tablet">
                <a href="https://int.istobal.com/inter/aviso-legal/" target="_blank" rel="noopener noreferrer">Legal Notice</a>
              </div>
              <div class="column is-narrow-desktop has-text-centered-mobile">
                <a href="https://int.istobal.com/inter/politica-privacidad/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div v-if="message=='Ok-OFF'">
      <p>
        <strong style="color: blue">Incrustando html:</strong><br>

        <strong>Esta es la manera que habías solicitado.</strong><br>
        Se carga el html del backend y si hay video (lo detecto con un id al iframe) pues puede realizar acciones<br>


      </p>
      <div class="containerbackend">
        <transition name="bounce">
          <div v-html="datahtml" v-if="estado=='durante'"></div>
        </transition>
        <transition name="bounce">
          <div v-html="datahtml" v-if="estado!='durante'"></div>
        </transition>
        <p v-if="estado=='durante'">
          Botones de ejemplo si quieres crear un botón de reproducción: <br />
          <input type="button" class="btn btn-primary" v-on:click="playVideo" value="Reproducir"/>
          <input type="button" class="btn btn-primary" v-on:click="stopVideo" value="Stop"/>
        </p>
      </div>




      <!--  DESCARTADO  HABILITAR A TRUE EL DIV DE ABAJO PARA VER -->
      <div v-if="false">
      <hr>
      <p style="margin-top: 50px">
        <strong style="color: red">Incrustando componente video: (descartado)</strong><br>
        Esta es la forma para incrustar el video con un componente<br>
        Lo dejo deshabilitado porque has pedido la de insertar un html.<br>
        Con esta forma solo haría falta pasar la url del video y yo detecto si esta presente y muestro el video <br>
        La pega es que si queremos luego poner botones de control con este componente es un poco raro. Además no he visto fácil por ejemplo cambiar el tamaño.<br>
        Lo bueno de este sería que nos manejaria urls de youtube o de vimeo
      </p>

      <transition name="bounce">
        <video-embed ref="youtube"  :src=urlvideo :params="{autoplay: 0,enablejsapi: 1, controls: 0, modestbranding: 0, rel: 0}" v-if="urlvideo!=''"></video-embed>
      </transition>
      <hr>
      </div>
      <!--  DESCARTADO   -->


    </div>
    <div v-else-if="message=='-OFF'">
      <strong>Petición mal formada o No se ha establecido contacto con el servidor</strong>
    </div>
    <div v-else-if="message=='-OFF'">
      No estás autorizado
    </div>
    <div v-if="debug==1" class="debug">
      <p><strong>Debug:</strong> Mensaje:{{message}} || Estado: {{estado}} || Pooling: {{ this.polling !== null ? true: false }}</p>

    </div>


  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'

export default {
  name: 'App',
  metaInfo () {
    return {
      title: this.$i18n.t('menu.Live') || 'Live',
      titleTemplate: '%s | ISTOBAL',
      link: [
        { rel: 'icon', type: 'image/x-icon', href: this.faviconPath },
        { rel: 'shortcut icon', type: 'image/x-icon', href: this.faviconPath }
      ]
    }
  },
  components: {
    FlipCountdown
  },
  props: ['locale'],
  computed:{
    urlvideo: function (){
      return atob(this.urlbk);
    }
  },
  data(){
    return {
      polling : null,
      token: null,
      datahtml: '',
      respuesta : null,
      urlbk: '',
      message: '',
      estado: '',
      player: '',
      debug: process.env.VUE_APP_DEBUGMODE || 0,
      iframecomponente: null,
      gallery: false,
      deadlinelabels : {
        days: this.$i18n.t('countDownLabels.days'),
        hours: this.$i18n.t('countDownLabels.hours'),
        minutes: this.$i18n.t('countDownLabels.minutes'),
        seconds: this.$i18n.t('countDownLabels.seconds'),
      },
      galleryImages: [
        { src: require('./assets/img/gallery-mwash4-01.jpg')},
        { src: require('./assets/img/gallery-mwash4-02.jpg')},
        { src: require('./assets/img/gallery-mwash4-03.jpg')},
        { src: require('./assets/img/gallery-mwash4-04.jpg')},
        { src: require('./assets/img/gallery-mwash4-05.jpg')},
        { src: require('./assets/img/gallery-mwash4-06.jpg')},
        { src: require('./assets/img/gallery-mwash4-07.jpg')},
        { src: require('./assets/img/gallery-mwash4-08.jpg')},
      ],
      faviconPath: require('./assets/favicon.png')
    }
  },
  methods: {
    queryBackend() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.token = params.get("token") || '';


      var data = JSON.stringify({
        token: this.token,
        player: this.player})

      this.axios.post('api/video.php', data).then((response) => {

        if (response.status==200){
          this.datahtml = atob(response.data.data)
          this.urlbk = response.data.urlvideo;
          this.message = response.data.message
          this.estado = response.data.estado
        }
      }).catch((error)=>{
          if (error.status==401){
            if (error.response){
              this.estado = error.response.data.estado
              this.message = error.response.data.message
            }
          }else{
            if (error.response){
              this.estado = error.response.data.estado
              this.message = error.response.data.message
            }
          }
      })

    },
    playVideo() {
      let start = '{"event":"command","func":"' + 'playVideo' + '","args":""}';

      let iframe = document.getElementById('myVideo')
      iframe.contentWindow.postMessage(start, '*')

      //let iframecomponente = document.getElementsByClassName('embed-responsive-item')[0]
      //console.log(iframe2)
      //iframecomponente.contentWindow.postMessage(start, '*')

    },
    stopVideo() {
      let stop='{"event":"command","func":"' + 'stopVideo' + '","args":""}'

      let iframe = document.getElementById('myVideo')
      iframe.contentWindow.postMessage(stop, '*')

      //let iframecomponente = document.getElementsByClassName('embed-responsive-item')[0]
      //iframecomponente.contentWindow.postMessage(stop, '*')
    },
    getImgUrl(value) {
        value += 50
        return `https://picsum.photos/id/10${value}/1230/500`
    },
    switchGallery(value) {
        this.gallery = value
        if (value) {
            return document.documentElement.classList.add('is-clipped')
        } else {
            return document.documentElement.classList.remove('is-clipped')
        }
    },
    goURL(url,base='media'){
      window.location.href=this.$i18n.t(base+'.'+url);
    }
  },
  mounted() {


    this.queryBackend()

    let sec = process.env.VUE_APP_POOLING_SECONDS * 1000;

    this.$i18n.locale = this.$route.params.locale
    //console.log('Idioma ' + this.$route.params.locale)
    this.deadlinelabels = {
        days: this.$i18n.t('countDownLabels.days'),
        hours: this.$i18n.t('countDownLabels.hours'),
        minutes: this.$i18n.t('countDownLabels.minutes'),
        seconds: this.$i18n.t('countDownLabels.seconds'),
      };

    this.polling = setInterval(() => {

      this.queryBackend()

    },sec)
    let iframeScript = document.createElement('script')
    iframeScript.setAttribute('src', 'https://player.vimeo.com/api/player.js')
    document.head.appendChild(iframeScript)


  },
  watch: {
    estado: function (newEstado,oldEstado){
      if (newEstado!==oldEstado){
        this.player = true
      }

    },
    urlvideo: function(newUrl,oldUrl){
      if (newUrl!=oldUrl){
        console.log('cambia')
        //this.changeUrl()
      }

    }
  }
}
</script>

<style>

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.debug {
  margin-top: 10px;
  font-size: 12px;
  padding: 10px;
  background-color: lightyellow;
  font-family: "Courier New";
}
.containerbackend {
  border: solid black 1px;
  text-align: center;
  padding: 10px;
}

</style>
