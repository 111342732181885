export default {
    "general": {
        "ISTOBAL"                     : "ISTOBAL"
    },
    "menu": {
        "Live"                        : "Evento en vivo",
        "Media"                       : "Media",
        "VRExperience"                : "Experiencia AR",
        "Contact"                     : "Contacto",
    },
    "countdown": {
        "title"                : "Presentación privada",
        "description"          : "Solicite acceso a su comercial.",
    },
    "pre": {
        "title"                       : "Estás a un paso de sentir algo único",
        "description"                 : "Bienvenido al evento virtual de ISTOBAL para presentar nuestro nuevo puente de lavado. ¿Y si pudiéramos conectar tecnología y emoción? Descúbrelo el próximo 8 de noviembre a las 11:00 (UTC+1) en nuestra emisión en directo.",
        "backgroundAlt"               : "Technology and emotion",
        "teaserTitle"                 : "Tecnología y emoción",
        "vimeoTeaserURL"              : "https://player.vimeo.com/video/572061245?h=5bcd0adc77&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    "durante": {
        "title"                       : "Bienvenido al evento virtual de ISTOBAL para presentar nuestro nuevo puente de lavado",
        "description"                 : "¿Y si pudiéramos conectar tecnología y emoción?",
        "live"                        : "En directo",
        "vimeoVideoURL"               : "https://vimeo.com/event/1415656/embed/108317c867",
        "vimeoChatURL"                : "https://vimeo.com/event/1184834/chat/",
    },
    "post": {
        "title"                       : "¿Te perdiste el evento virtual para presentar nuestro nuevo puente M'WASH4?",
        "description"                 : "No te preocupes puedes verlo completo aquí. ¿Y si pudiéramos conectar tecnología y emoción?",
        "vimeoVideoURL"               : "https://player.vimeo.com/video/643434991?h=de5fbde57f",
        "vimeoYoutubeURL"             : "https://www.youtube.com/embed/BawgModtcnE?rel=0",
        "surveyTitle"                 : "¿Qué te ha parecido el nuevo M'WASH 4?",
        "surveyDescription"           : "Queremos saber tu opinión",
        "surveyButtonText"            : "Acceder a la encuesta",
        "surveyURL"                   : "https://oufv23ymd2d.typeform.com/to/Yoq7wg6y?utm_source=typeform&utm_medium=website&utm_campaign=mwash4&utm_content=danos-tu-opinion",
    },
    "media": {
        "title"                       : "Media",
        "vimeoVideoSpotURL"           : "https://player.vimeo.com/video/637373830?h=5f38fdbb51",
        "vimeoVideoTechnicalURL"      : "https://player.vimeo.com/video/637374855?h=831e78136c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        "downloadPDF"                 : "Descargar PDF",
        "catalogPDFName"              : "Catálogo M'WASH4",
        "catalogPDFAlt"               : "Nuevo puente de lavado M'WASH4",
        "catalogPDFURL"               : "/pdf/Catalogo_MWASH4.pdf",
        "pressReleasePDFName"         : "Nota de prensa",
        "pressReleasePDFAlt"          : "Nuevo puente de lavado M'WASH4",
        "pressReleasePDFURL"          : "/pdf/Nota-Prensa-ISTOBAL-MWASH4.pdf",
        "viewGallery"                 : "Ver galería",
    },
    "ar": {
        "title"                       : "Realidad Aumentada",
        "description"                 : "Descubre el nuevo puente de lavado ISTOBAL M'WASH4 a través de la experiencia de realidad aumentada más emocionante."
    },
    "contact": {
        "title"                       : "Contacto",
        "preDescription"              : "¿Quieres saber más? Contacta con nuestro equipo comercial para conocer más detalles sobre el lanzamiento de nuestro nuevo puente.",
        "description"                 : "¿Quieres saber más? Contacta con nuestro equipo comercial para conocer más detalles sobre nuestro nuevo puente ISTOBAL M'WASH4.",
        "iframeURL"                   : "/form/contact-es.html",
        "iframeURLThanks"             : "/form/gracias.html",
    },
    "schedule": {
        "Menu"                        : "Agenda",
        "Title"                       : "Agenda",
    },
    "countDownLabels": {
        "days"                        : "días",
        "hours"                       : "horas",
        "minutes"                     : "minutos",
        "seconds"                     : "segundos"
    }
}
